import { TranslocoService } from '@jsverse/transloco'

export const timeRenderer = (time: number | undefined | null) => {
  if (time === undefined || time === null) return '---'
  const horas = Math.floor(time / 3600)
  const minutos = Math.floor((time % 3600) / 60)
  return (
    `${horas !== 0 && minutos !== 0 ? horas + 'h ' : ''}${minutos !== 0 ? (horas < 0 && minutos < 0 ? -minutos : minutos) + 'm' : ''}` ||
    '0h'
  )
}

export const monthRenderer = (
  month: number | undefined | null,
  translate: TranslocoService
) => {
  if (month === undefined || month === null) return '---'
  switch (month) {
    case 1:
      return translate.translate('SATINELUI.COMMON.MONTHS.JANUARY')
    case 2:
      return translate.translate('SATINELUI.COMMON.MONTHS.FEBRUARY')
    case 3:
      return translate.translate('SATINELUI.COMMON.MONTHS.MARCH')
    case 4:
      return translate.translate('SATINELUI.COMMON.MONTHS.APRIL')
    case 5:
      return translate.translate('SATINELUI.COMMON.MONTHS.MAY')
    case 6:
      return translate.translate('SATINELUI.COMMON.MONTHS.JUNE')
    case 7:
      return translate.translate('SATINELUI.COMMON.MONTHS.JULY')
    case 8:
      return translate.translate('SATINELUI.COMMON.MONTHS.AUGUST')
    case 9:
      return translate.translate('SATINELUI.COMMON.MONTHS.SEPTEMBER')
    case 10:
      return translate.translate('SATINELUI.COMMON.MONTHS.OCTOBER')
    case 11:
      return translate.translate('SATINELUI.COMMON.MONTHS.NOVEMBER')
    case 12:
      return translate.translate('SATINELUI.COMMON.MONTHS.DECEMBER')
    default:
      return '---'
  }
}

export const weekdayRenderer = (
  day: number | undefined | null,
  translate: TranslocoService
) => {
  if (day === undefined || day === null) return '---'
  switch (day) {
    case 1:
      return translate.translate('SATINELUI.COMMON.WEEKDAYS.MONDAY')
    case 2:
      return translate.translate('SATINELUI.COMMON.WEEKDAYS.TUESDAY')
    case 3:
      return translate.translate('SATINELUI.COMMON.WEEKDAYS.WEDNESDAY')
    case 4:
      return translate.translate('SATINELUI.COMMON.WEEKDAYS.THURSDAY')
    case 5:
      return translate.translate('SATINELUI.COMMON.WEEKDAYS.FRIDAY')
    case 6:
      return translate.translate('SATINELUI.COMMON.WEEKDAYS.SATURDAY')
    case 0:
    case 7:
      return translate.translate('SATINELUI.COMMON.WEEKDAYS.SUNDAY')
    default:
      return '---'
  }
}
